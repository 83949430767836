import React, { Component } from 'react';
import SubCategoryService from '../../services/SubCategoryService';
import Navbar from '../../components/navbar/NavBar';
import '../../assets/styles/globalstyle.css';
class SubCategoryListComponent extends Component {
    
    constructor(props) {
        super(props)

        this.state = {
            subCategories: []
        }
        this.addSubCategory = this.addSubCategory.bind(this);
        this.editSubCategory = this.editSubCategory.bind(this);
        this.deleteSubCategory = this.deleteSubCategory.bind(this);
        this.typeList = this.typeList.bind(this);
    }

    componentDidMount() {
        SubCategoryService.getSubCategories().then((res) => {
            console.log(res.data);
            this.setState({ subCategories: res.data })
        }).catch((error) => {
            if(error.request.status.toString() === "401" && error.response.data.error.toString() === "Unauthorized"){
                this.props.history.push('/');
            }
        });
    }

    addSubCategory(id) {
        this.props.history.push('/add-subcategory/-1');
    }

    typeList(id) {
        this.props.history.push('/types');
    }

    editSubCategory(id) {
        this.props.history.push(`/add-subcategory/${id}`);
    }

    deleteSubCategory(id) {
        SubCategoryService.deleteSubCategory(id).then((res) => {
            this.setState({
                subCategories: this.state.subCategories.filter(subCategory => subCategory.id !== id)
            });
        }).catch((error) => {
            if(error.request.status.toString() === "401" && error.response.data.error.toString() === "Unauthorized"){
                this.props.history.push('/');
            }
        });
    }

    render() {
        return (
            <div>
                <Navbar />
                <div className="container width-60" >
                <h2 className="text-center">SubCategories List</h2>
                    <div className="row">
                        <div className="col-md-9"></div>
                        <div className="col-md-3"><button className="btn btn-primary" onClick={this.addSubCategory}>Add SubCategory</button></div>
                        
                    </div>
                <br></br>
                <div className="row">
                    <table className="table table-striped table-bordred">
                        <thead>
                            <tr>
                                <th>SubCategory Title </th>
                                <th>Category </th>
                                <th>Actions </th>
                            </tr>
                        </thead>
                        <tbody>
                            {
                                this.state.subCategories.map(
                                    subCategory =>
                                        <tr key={subCategory.id}>
                                            <td> {subCategory.name} </td>
                                            <td> {subCategory.category.title} </td>

                                            <td>

                                                <button onClick={() => this.editSubCategory(subCategory.id)} className="btn btn-info mr-2">Update </button>
                                                <button onClick={() => this.deleteSubCategory(subCategory.id)} className="btn btn-danger">Delete </button>
                                            </td>
                                        </tr>
                                )
                            }
                        </tbody>
                    </table>
                </div>

                </div>
                
            </div >
        );
    }
}

export default SubCategoryListComponent;