

const global = {

    //BASE_URL : "http://localhost:9090/api/v1"
    
    BASE_URL : "https://poc-spring-backend.herokuapp.com/api/v1"

}

export default global