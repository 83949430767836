import React, { Component } from 'react';
import ElementService from '../services/ElementService';
import Navbar from '../components/navbar/NavBar';
import '../assets/styles/globalstyle.css';
class ListElementComponent extends Component {
    constructor(props) {
        super(props)

        this.state = {
            elements: []
        }
        this.addElement = this.addElement.bind(this);
        this.categoryList = this.categoryList.bind(this);
        this.deleteElement = this.deleteElement.bind(this);
        this.editElement = this.editElement.bind(this);
        
    }

    componentDidMount() {
        ElementService.getElements().then((res) => {
            console.log(res.data);
            this.setState({ elements: res.data })
        });

    }

    addElement(id) {
        this.props.history.push('/add-element/-1');
    }

    categoryList(id){
        this.props.history.push('/categories');
    }

    editElement(id) {
        this.props.history.push(`/add-element/${id}`);
    }

    deleteElement(id) {
        ElementService.deleteElement(id).then((res) => {
            this.setState({
                elements: this.state.elements.filter(element => element.id !== id)
            });
        });
    }
    
    render() {
        return (
            <div>
                <Navbar />
                <div className="container width-60" >

                <h2 className="text-center">Elements List</h2>
                <div className="row">
                        <div className="col-md-9"></div>
                        <div className="col-md-3"><button className="btn btn-primary" onClick={this.addElement}>Add Element</button></div>
                        
                    </div>
                
                <br></br>
                <div className="row">
                    <table className="table table-striped table-bordred">
                        <thead>
                            <tr>
                                <th>Element Title </th>
                                <th>Kg eq.CO2</th>
                                <th>Type Name</th>
                                <th>SubCategory Name </th>
                                <th>Category Name </th>
                                <th>Actions </th>
                            </tr>
                        </thead>
                        <tbody>

                            {
                                this.state.elements.map(
                                    element =>
                                        <tr key={element.id}>
                                            <td> {element.title} </td>
                                            
                                            <td> {element.weight} </td>
                                            <td> {element.type.name} </td>
                                            <td> {element.type.subCategory.name} </td>
                                            <td> {element.type.subCategory.category.title} </td>
                                            <td>

                                                <button onClick={() => this.editElement(element.id)} className="btn btn-info mr-2">Update </button>
                                                <button onClick={() => this.deleteElement(element.id)} className="btn btn-danger">Delete </button>
                                            </td>
                                        </tr>
                                )
                            }
                        </tbody>
                    </table>
                </div>


                </div>
                
            </div >
        );
    }
}

export default ListElementComponent;