import axios from 'axios';
import globalConstants from '../utils/globalConstants';
const TYPE_API_BASE_URL = "/types";

class TypeService {

    getToken=()=>{
        return localStorage.getItem('USER_KEY');
    }

    getTypes() {
        return  axios({
            method:'GET',
            url:globalConstants.BASE_URL + TYPE_API_BASE_URL,
            headers:{
                'Authorization':'Bearer '+this.getToken()
            }
        });
        
    }

    createType(type) {
        return  axios({
            method:'POST',
            url:globalConstants.BASE_URL + TYPE_API_BASE_URL,
            data:type,
            headers:{
                'Authorization':'Bearer '+this.getToken()
            }
        });
        
    }

    getTypeById(typeId) {
        return  axios({
            method:'GET',
            url:globalConstants.BASE_URL + TYPE_API_BASE_URL + "/" + typeId,
            headers:{
                'Authorization':'Bearer '+this.getToken()
            }
        });
        
    }

    updateType(type, typeId) {
        return  axios({
            method:'PUT',
            url:globalConstants.BASE_URL + TYPE_API_BASE_URL + "/" + typeId,
            data:type,
            headers:{
                'Authorization':'Bearer '+this.getToken()
            }
        });
        
    }

    deleteType(typeId) {
        return  axios({
            method:'DELETE',
            url:globalConstants.BASE_URL + TYPE_API_BASE_URL + "/" + typeId,
            headers:{
                'Authorization':'Bearer '+this.getToken()
            }
        });
        
    }
}

export default new TypeService()