import axios from 'axios';
import globalConstants from '../utils/globalConstants';
const LOGIN_AUTH_API = "/auth/login";
const USER_INFO_URL = "/auth/userinfo";
class AuthenticationService {



    getToken=()=>{
        return localStorage.getItem('USER_KEY');
    }
    
    userLogin=(authRequest)=>{
        return axios({
            'method':'POST',
            'url':`${process.env.hostUrl||globalConstants.BASE_URL}`+ LOGIN_AUTH_API,
            'data':authRequest,
            
        })
    }
    
    fetchUserData=(authRequest)=>{
        return axios({
            method:'GET',
            url:`${process.env.hostUrl||globalConstants.BASE_URL}`+ USER_INFO_URL,
            headers:{
                'Authorization':'Bearer '+this.getToken(),
                
            }
        })
    }

}

export default new AuthenticationService()
