import React, { Component } from 'react';
import CountryService from '../../services/CountryService';
import Navbar from '../navbar/NavBar';
import '../../assets/styles/globalstyle.css';
class CountryListComponent extends Component {
    
    constructor(props) {
        super(props)

        this.state = {
            countries: []
        }
        this.addCountry = this.addCountry.bind(this);
        this.editCountry = this.editCountry.bind(this);
        this.deleteCountry = this.deleteCountry.bind(this);
    }

    componentDidMount() {
        CountryService.getCountries().then((res) => {
            console.log(res.data);
            this.setState({ countries: res.data })
        }).catch((error) => {
            if(error.request.status.toString() === "401" && error.response.data.error.toString() === "Unauthorized"){
                this.props.history.push('/');
            }
        });
    }

    addCountry(id) {
        this.props.history.push('/add-country/-1');
    }

   

    editCountry(id) {
        this.props.history.push(`/add-country/${id}`);
    }

    deleteCountry(id) {
        CountryService.deleteCountry(id).then((res) => {
            this.setState({
                countries: this.state.countries.filter(country => country.id !== id)
            });
        }).catch((error) => {
            if(error.request.status.toString() === "401" && error.response.data.error.toString() === "Unauthorized"){
                this.props.history.push('/');
            }
        });
    }

    render() {
        return (
            <div>
                <Navbar />
                <div className="container width-60" >
                <h2 className="text-center">Countries List</h2>
                    <div className="row">
                        <div className="col-md-9"></div>
                        <div className="col-md-3"><button className="btn btn-primary" onClick={this.addCountry}>Add Country</button></div>
                        
                    </div>
                <br></br>
                <div className="row">
                    <table className="table table-striped table-bordred">
                        <thead>
                            <tr>
                                <th>ID </th>
                                <th>Country Name </th>
                                
                                <th>Actions </th>
                            </tr>
                        </thead>
                        <tbody>
                            {
                                this.state.countries.map(
                                    country =>
                                        <tr key={country.id}>
                                            <td> {country.id} </td>
                                            <td> {country.name} </td>
                                           
                                            <td>

                                                <button onClick={() => this.editCountry(country.id)} className="btn btn-info mr-2">Update </button>
                                                <button onClick={() => this.deleteCountry(country.id)} className="btn btn-danger">Delete </button>
                                            </td>
                                        </tr>
                                )
                            }
                        </tbody>
                    </table>
                </div>

                </div>
                
            </div >
        );
    }
}

export default CountryListComponent;