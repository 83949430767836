import React, { Component } from 'react';
import SubCategoryService from '../../services/SubCategoryService';
import CategoryService from '../../services/CategoryService';

class AddSubCategoryComponent extends Component {
    
    constructor(props) {
        super(props)

        this.state = {
            id: this.props.match.params.id,
            name: '',
            categories: [],
            category: null

        }
        this.changeNameHandler = this.changeNameHandler.bind(this);
        this.saveOrUpdateSubCategory = this.saveOrUpdateSubCategory.bind(this);
        this.changeCategoryHandler = this.changeCategoryHandler.bind(this);
    }

    componentDidMount() {
        CategoryService.getCategories().then((res) => {
            console.log(res.data);
            if(res.data.length > 0){
                this.setState({ category: res.data[0] });
                console.log("componentDidMount:",res.data[0]);
            }else{
                this.props.history.push('/subcategories');
            }
            this.setState({ categories: res.data })
        }).catch((error) => {
            if(error.request.status.toString() === "401" && error.response.data.error.toString() === "Unauthorized"){
                this.props.history.push('/');
            }
        });
        if (this.state.id === "-1") {
            return;
        } else {
            SubCategoryService.getSubCategoryById(this.state.id).then((res) => {
                console.log("getSubCategoryById",res.data);
                let subCategory = res.data;
                this.setState({
                    name: subCategory.name,
                    category: subCategory.category
                });
            }).catch((error) => {
                if(error.request.status.toString() === "401" && error.response.data.error.toString() === "Unauthorized"){
                    this.props.history.push('/');
                }
            });
        }

    }

    saveOrUpdateSubCategory = (e) => {
        e.preventDefault();
        let subCategory = { name: this.state.name, category: this.state.category };
        console.log('subCategory => ' + JSON.stringify(subCategory));
        if (this.state.id === "-1") {
            SubCategoryService.createSubCategory(subCategory).then(res => {
                this.props.history.push("/subcategories");
            }).catch((error) => {
                if(error.request.status.toString() === "401" && error.response.data.error.toString() === "Unauthorized"){
                    this.props.history.push('/');
                }
            });
        } else {
            SubCategoryService.updateSubCategory(subCategory, this.state.id).then(res => {
                this.props.history.push("/subcategories");
            }).catch((error) => {
                if(error.request.status.toString() === "401" && error.response.data.error.toString() === "Unauthorized"){
                    this.props.history.push('/');
                }
            });

        }

    }

    changeNameHandler = (event) => {
        this.setState({ name: event.target.value });
    }

    

    changeCategoryHandler = (event) => {
        
        for (let i = 0; i < this.state.categories.length; i++) {
            if(this.state.categories[i].id.toString() === event.target.value.toString()){
                console.log("changeCategoryHandler");
                this.setState({ category: this.state.categories[i] });
            }       
        }
    }


    cancel() {
        this.props.history.push('/subcategories');
    }

    getTitle() {
        if (this.state.id === "-1") {
            return <h3 className="text-center">Add SubCategory</h3>
        } else {
            return <h3 className="text-center">Update SubCategory</h3>
        }
    }
    render() {
        return (
            <div>
                <br></br>
                <div className="container">
                    <div className="row">
                        <div className="card col-md-6 offset-md-3 offset-md-3">
                            {
                                this.getTitle()
                            }
                            <div className="card-body">
                                <form>
                                    <div className="form-group">
                                        <label> Name: </label>
                                        <input placeholder="Title" name="name" className="form-control"
                                            value={this.state.name} onChange={this.changeNameHandler} />
                                    </div>
                                    
                                    <div className="form-group">
                                        <select  className="form-select" aria-label="Default select example" name="category" onChange={this.changeCategoryHandler}>
                                            {this.state.categories.map(category =>
                                            <option selected={this.state.category.id === category.id} value={category.id}>{category.title}</option>
                                            )};
                                        </select>
                                    </div>

                                    <button className="btn btn-success" onClick={this.saveOrUpdateSubCategory}>Save</button>
                                    <button className="btn btn-danger" onClick={this.cancel.bind(this)} style={{ marginLeft: "10px" }}>Cancel</button>
                                </form>
                            </div>
                        </div>
                    </div>

                </div>
            </div>
        );
    }
}

export default AddSubCategoryComponent;