import React, { Component } from 'react';
import TypeService from '../../services/TypeService';
import Navbar from '../../components/navbar/NavBar';
import '../../assets/styles/globalstyle.css';
class TypeListComponent extends Component {
    
    constructor(props) {
        super(props)

        this.state = {
            types: []
        }
        this.addType = this.addType.bind(this);
        this.editType = this.editType.bind(this);
        this.deleteType = this.deleteType.bind(this);
        this.elementList = this.elementList.bind(this);
    }

    componentDidMount() {
        TypeService.getTypes().then((res) => {
            console.log(res.data);
            this.setState({ types: res.data })
        }).catch((error) => {
            if(error.request.status.toString() === "401" && error.response.data.error.toString() === "Unauthorized"){
                this.props.history.push('/');
            }
        });
    }

    addType(id) {
        this.props.history.push('/add-type/-1');
    }

    elementList(id) {
        this.props.history.push('/elements');
    }

    editType(id) {
        this.props.history.push(`/add-type/${id}`);
    }

    deleteType(id) {
        TypeService.deleteType(id).then((res) => {
            this.setState({
                types: this.state.types.filter(type => type.id !== id)
            });
        }).catch((error) => {
            if(error.request.status.toString() === "401" && error.response.data.error.toString() === "Unauthorized"){
                this.props.history.push('/');
            }
        });
    }

    render() {
        return (
            <div>
                <Navbar />
                <div className="container width-60" >
                <h2 className="text-center">Types List</h2>
                    <div className="row">
                        <div className="col-md-9"></div>
                        <div className="col-md-3"><button className="btn btn-primary" onClick={this.addType}>Add Type</button></div>
                        
                    </div>
                <br></br>
                <div className="row">
                    <table className="table table-striped table-bordred">
                        <thead>
                            <tr>
                                <th>Type Name </th>
                                <th>Sub Category </th>
                                <th>Category Name </th>
                                <th>Measures </th>
                                <th>Actions </th>
                            </tr>
                        </thead>
                        <tbody>
                            {
                                this.state.types.map(
                                    type =>
                                        <tr key={type.id}>
                                            <td> {type.name} </td>
                                            <td> {type.subCategory.name} </td>
                                            <td> {type.subCategory.category.title} </td>
                                            <td> {type.measures.map((measure, index) => measure.name + ";"
                                           
                                            )} </td>
                                            <td>

                                                <button onClick={() => this.editType(type.id)} className="btn btn-info mr-2">Update </button>
                                                <button onClick={() => this.deleteType(type.id)} className="btn btn-danger">Delete </button>
                                            </td>
                                        </tr>
                                )
                            }
                        </tbody>
                    </table>
                </div>

                </div>
                
            </div >
        );
    }
}

export default TypeListComponent;