import axios from 'axios';
import globalConstants from '../utils/globalConstants';
const SUBCATEGORY_API_BASE_URL = "/subcategories";

class SubCategoryService {

    getToken=()=>{
        return localStorage.getItem('USER_KEY');
    }

    getSubCategories() {
        return  axios({
            method:'GET',
            url:globalConstants.BASE_URL + SUBCATEGORY_API_BASE_URL,
            headers:{
                'Authorization':'Bearer '+this.getToken()
            }
        });
        
    }

    createSubCategory(subCategory) {
        return  axios({
            method:'POST',
            url:globalConstants.BASE_URL + SUBCATEGORY_API_BASE_URL,
            data:subCategory,
            headers:{
                'Authorization':'Bearer '+this.getToken()
            }
        });
        
    }

    getSubCategoryById(subCategoryId) {
        return  axios({
            method:'GET',
            url:globalConstants.BASE_URL + SUBCATEGORY_API_BASE_URL + "/" + subCategoryId,
            headers:{
                'Authorization':'Bearer '+this.getToken()
            }
        });
        
    }

    updateSubCategory(subCategory, subCategoryId) {
        return  axios({
            method:'PUT',
            url:globalConstants.BASE_URL + SUBCATEGORY_API_BASE_URL + "/" + subCategoryId,
            data:subCategory,
            headers:{
                'Authorization':'Bearer '+this.getToken()
            }
        });
        
    }

    deleteSubCategory(subCategoryId) {
        return  axios({
            method:'DELETE',
            url:globalConstants.BASE_URL + SUBCATEGORY_API_BASE_URL + "/" + subCategoryId,
            headers:{
                'Authorization':'Bearer '+this.getToken()
            }
        });
        
    }
}

export default new SubCategoryService()