import React, { Component } from 'react';
import MeasureService from '../../services/MeasureService';
import Navbar from '../navbar/NavBar';
import '../../assets/styles/globalstyle.css';
class MeasureListComponent extends Component {
    
    constructor(props) {
        super(props)

        this.state = {
            measures: []
        }
        this.addMeasure = this.addMeasure.bind(this);
        this.editMeasure = this.editMeasure.bind(this);
        this.deleteMeasure = this.deleteMeasure.bind(this);
    }

    componentDidMount() {
        MeasureService.getMeasures().then((res) => {
            console.log(res.data);
            this.setState({ measures: res.data })
        }).catch((error) => {
            if(error.request.status.toString() === "401" && error.response.data.error.toString() === "Unauthorized"){
                this.props.history.push('/');
            }
        });
    }

    addMeasure(id) {
        this.props.history.push('/add-measure/-1');
    }

   

    editMeasure(id) {
        this.props.history.push(`/add-measure/${id}`);
    }

    deleteMeasure(id) {
        MeasureService.deleteMeasure(id).then((res) => {
            this.setState({
                measures: this.state.measures.filter(measure => measure.id !== id)
            });
        }).catch((error) => {
            if(error.request.status.toString() === "401" && error.response.data.error.toString() === "Unauthorized"){
                this.props.history.push('/');
            }
        });
    }

    render() {
        return (
            <div>
                <Navbar />
                <div className="container width-60" >
                <h2 className="text-center">Measures List</h2>
                    <div className="row">
                        <div className="col-md-9"></div>
                        <div className="col-md-3"><button className="btn btn-primary" onClick={this.addMeasure}>Add Measure</button></div>
                        
                    </div>
                <br></br>
                <div className="row">
                    <table className="table table-striped table-bordred">
                        <thead>
                            <tr>
                                <th>ID </th>
                                <th>Measure Name </th>
                                <th>Actions </th>
                            </tr>
                        </thead>
                        <tbody>
                            {
                                this.state.measures.map(
                                    measure =>
                                        <tr key={measure.id}>
                                            <td> {measure.id} </td>
                                            <td> {measure.name} </td>
                                           
                                            <td>
                                                <button onClick={() => this.editMeasure(measure.id)} className="btn btn-info mr-2">Update </button>
                                                <button onClick={() => this.deleteMeasure(measure.id)} className="btn btn-danger">Delete </button>
                                            </td>
                                        </tr>
                                )
                            }
                        </tbody>
                    </table>
                </div>

                </div>
                
            </div >
        );
    }
}

export default MeasureListComponent;