import axios from 'axios';
import globalConstants from '../utils/globalConstants'
const ELEMENT_API_BASE_URL = "/elements";

class ElementService {

    getToken=()=>{
        return localStorage.getItem('USER_KEY');
    }

    getElements() {
        return  axios({
            method:'GET',
            url:globalConstants.BASE_URL + ELEMENT_API_BASE_URL,
            headers:{
                'Authorization':'Bearer '+this.getToken()
            }
        });
        
    }

    createElement(element) {
        return  axios({
            method:'POST',
            url:globalConstants.BASE_URL + ELEMENT_API_BASE_URL,
            data:element,
            headers:{
                'Authorization':'Bearer '+this.getToken()
            }
        });
        
    }

    getElementById(elementId) {
        return  axios({
            method:'GET',
            url:globalConstants.BASE_URL + ELEMENT_API_BASE_URL + "/" + elementId,
            headers:{
                'Authorization':'Bearer '+this.getToken()
            }
        });
        
    }

    updateElement(element, elementId) {
        return  axios({
            method:'PUT',
            url:globalConstants.BASE_URL + ELEMENT_API_BASE_URL + "/" + elementId,
            data:element,
            headers:{
                'Authorization':'Bearer '+this.getToken()
            }
        });
        
    }

    deleteElement(elementId) {
        return  axios({
            method:'DELETE',
            url:globalConstants.BASE_URL + ELEMENT_API_BASE_URL + "/" + elementId,
            headers:{
                'Authorization':'Bearer '+this.getToken()
            }
        });
        
    }
}

export default new ElementService()