import axios from 'axios';
import globalConstants from '../utils/globalConstants';
const CATEGORY_API_BASE_URL = "/categories";

class CategoryService {
    
    getToken=()=>{
        return localStorage.getItem('USER_KEY');
    }

    getCategories() {
        return  axios({
            method:'GET',
            url: globalConstants.BASE_URL + CATEGORY_API_BASE_URL,
            headers:{
                'Authorization':'Bearer '+this.getToken()
            }
        });
    }

    createCategory(category) {
        return  axios({
            method:'POST',
            url: globalConstants.BASE_URL + CATEGORY_API_BASE_URL,
            data:category,
            headers:{
                'Authorization':'Bearer '+this.getToken()
            }
        });
        
    }

    getCategoryById(categoryId) {
        return  axios({
            method:'GET',
            url: globalConstants.BASE_URL + CATEGORY_API_BASE_URL + "/" + categoryId,
            headers:{
                'Authorization':'Bearer '+this.getToken()
            }
        });
        
    }

    updateCategory(category, categoryId) {
        return  axios({
            method:'PUT',
            url: globalConstants.BASE_URL + CATEGORY_API_BASE_URL + "/" + categoryId,
            data:category,
            headers:{
                'Authorization':'Bearer '+this.getToken()
            }
        });
        
    }

    deleteCategory(categoryId) {
        return  axios({
            method:'DELETE',
            url: globalConstants.BASE_URL + CATEGORY_API_BASE_URL + "/" + categoryId,
            headers:{
                'Authorization':'Bearer '+this.getToken()
            }
        });
       
    }
}

export default new CategoryService()