import React, { Component } from 'react';
import ElementService from '../services/ElementService';
import TypeService from '../services/TypeService';
import { Modal, Button} from "react-bootstrap";
import CountryService from '../services/CountryService';

class CreateElementComponent extends Component {
    
    constructor(props) {
        super(props)

        this.state = {
            id: this.props.match.params.id,
            title: '',
            description: '',
            weight: 0.0,
            typeList: [],
            type: null,
            selectedCountries: [],
            countries: [],
            isOpen: false

        }
        this.changeTitleHandler = this.changeTitleHandler.bind(this);
        this.changeDescriptionHandler = this.changeDescriptionHandler.bind(this);
        this.saveOrUpdateElement = this.saveOrUpdateElement.bind(this);
        this.changeTypeHandler = this.changeTypeHandler.bind(this);
        this.changeWeightHandler = this.changeWeightHandler.bind(this);
        this.deleteSelectedCountry = this.deleteSelectedCountry.bind(this);
        this.closeModal = this.closeModal.bind(this);
        this.openModal = this.openModal.bind(this);
        this.addToSelectedCountry = this.addToSelectedCountry.bind(this);
        this.ifCountryIsAlreadyExist = this.ifCountryIsAlreadyExist.bind(this);
    }

    componentDidMount() {
        TypeService.getTypes().then((res) => {
            var type = null;
            if(res.data.length > 0){
                type = res.data[0];
                
                
                console.log("componentDidMount:",res.data[0]);
            }else{
                this.props.history.push('/elements');
            }
            this.setState({ 
                typeList: res.data, type: type
            })
        }).catch((error) => {
            if(error.request.status.toString() === "401" && error.response.data.error.toString() === "Unauthorized"){
                console.log("go to login");
                this.props.history.push('/');
            }
        });
       
        CountryService.getCountries().then(res => {
            
            console.log(res);
            if(res.data.length > 0){
                this.setState({ countries: res.data });
            }else{
                this.props.history.push('/elements');
            }
        }).catch((error) => {
            if(error.request.status.toString() === "401" && error.response.data.error.toString() === "Unauthorized"){
                console.log("go to login");
                this.props.history.push({pathname: '/',state: { isError: true }});
            }
        });
        
        if (this.state.id === "-1") {
            return;
        } else {
            ElementService.getElementById(this.state.id).then((res) => {
                console.log("getElementById",res.data);
                let element = res.data;
                this.setState({
                    title: element.title,
                    description: element.description,
                    weight : element.weight,
                    type: element.type,
                    selectedCountries: element.countries
                });
            }).catch((error) => {
                if(error.request.status.toString() === "401" && error.response.data.error.toString() === "Unauthorized"){
                    console.log("go to login");
                    this.props.history.push({pathname: '/',state: { isError: true }});
                }
            });
        }

        
    }

    saveOrUpdateElement = (e) => {
        e.preventDefault();
        let element = { title: this.state.title, description: this.state.description, weight: this.state.weight, type: this.state.type, countries: this.state.selectedCountries };
        console.log('element => ' + JSON.stringify(element));
        if (this.state.id === "-1") {
            ElementService.createElement(element).then(res => {
                this.props.history.push("/elements");
            }).catch((error) => {
                if(error.request.status.toString() === "401" && error.response.data.error.toString() === "Unauthorized"){
                    console.log("go to login");
                    this.props.history.push('/');
                }
            });
        } else {
            ElementService.updateElement(element, this.state.id).then(res => {
                this.props.history.push("/elements");
            }).catch((error) => {
                if(error.request.status.toString() === "401" && error.response.data.error.toString() === "Unauthorized"){
                    console.log("go to login");
                    this.props.history.push('/');
                }
            });
        }
    }

    changeTitleHandler = (event) => {
        this.setState({ title: event.target.value });
    }

    changeDescriptionHandler = (event) => {
        this.setState({ description: event.target.value });
    }

    changeWeightHandler = (event) => {
        
        var value = event.target.value.replace(",", ".");
        this.setState({ weight: value });
    }

    changeTypeHandler = (event) => {
        for (let i = 0; i < this.state.typeList.length; i++) {
            if(this.state.typeList[i].id.toString() === event.target.value.toString()){
                this.setState({ type: this.state.typeList[i] });
            }       
        }
    }

    deleteSelectedCountry = (event) => {
        console.log("deleteSelectedCountry");
        console.log(event.target.innerText);
        var array = [...this.state.selectedCountries];
        if(array.length === 1){
            this.setState({ selectedCountries: []});
        }else{
            for (let i = 0; i < this.state.selectedCountries.length; i++) {
                console.log(this.state.selectedCountries[i].name.toString());
                
                if(this.state.selectedCountries[i].name.toString().trim() === event.target.innerText.toString().trim()){
                    console.log("if");
                    array.splice(i, 1);
                    console.log(array);
                    this.setState({ selectedCountries: array});
                }
                
            }
            console.log("endfor");
        }
        
        
    }

    addToSelectedCountry = (event) =>{
        console.log(event.target.innerText);
        var array = [...this.state.selectedCountries];
        if(!this.ifCountryIsAlreadyExist(event.target.innerText)){
            for (let i = 0; i < this.state.countries.length; i++) {
                if(this.state.countries[i].name.toString().trim() === event.target.innerText.toString().trim()){
                    this.setState({ 
                        selectedCountries: array.concat(this.state.countries[i]), 
                        isOpen: false
                    });
                }
            }
        }
    }

    ifCountryIsAlreadyExist(name){
        for (let i = 0; i < this.state.selectedCountries.length; i++) {
            if(this.state.selectedCountries[i].name.toString().trim() === name.toString().trim()){
                return true;
            }
        }
        return false;
    }

    openModal (){
        this.setState({ isOpen: true });
    }

    closeModal (){
         this.setState({ isOpen: false });
    }

    cancel() {
        this.props.history.push('/elements');
    }

    getTitle() {
        if (this.state.id === "-1") {
            return <h3 className="text-center">Add Element</h3>
        } else {
            return <h3 className="text-center">Update Element</h3>
        }
    }

    render() {
        return (
            <div>
                <br></br>
                <div className="container">
                    <div className="row">
                        <div className="card col-md-6 offset-md-3 offset-md-3">
                            {
                                this.getTitle()
                            }
                            <div className="card-body">
                                <form>
                                    <div className="form-group">
                                        <label> Title: </label>
                                        <input placeholder="Title" name="title" className="form-control"
                                            value={this.state.title} onChange={this.changeTitleHandler} />
                                    </div>
                                    <div className="form-group">
                                        <label> Description: </label>
                                        <input placeholder="Description" name="description" className="form-control"
                                            value={this.state.description} onChange={this.changeDescriptionHandler} />
                                    </div>
                                    <div className="form-group">
                                        <label> Weight (Kg eq.CO2): </label>
                                        <input  placeholder="Weight" name="weight" className="form-control"
                                            value={this.state.weight} onChange={this.changeWeightHandler} />
                                    </div>
                                    <div className="form-group">
                                        <label> Type: </label>
                                        <select value={this.state.type !== null ? this.state.type.id: ''}  className="form-select" aria-label="Default select example" name="type" onChange={this.changeTypeHandler}>
                                            {this.state.typeList.map(type =>
                                            <option key={type.id} selected={this.state.type.id === type.id} value={type.id}>{type.name} - {type.subCategory.name}</option>
                                            )};
                                        </select>
                                    </div>

                                    <div className="form-group">
                                        <Button variant="primary" onClick={this.openModal}>
                                            Launch Country list
                                        </Button>
                                    </div>

                                    <div className="form-group">
                                    <div className="list-group">
                                        <label> Selected Countries: </label>
                                            { this.state.selectedCountries.map(country => <button onClick={this.deleteSelectedCountry} type="button" className="list-group-item list-group-item-action">{country.name}</button> ) 
                                            }
                                        </div>
                                    </div>
                                    

                                    <button className="btn btn-success" onClick={this.saveOrUpdateElement}>Save</button>
                                    <button className="btn btn-danger" onClick={this.cancel.bind(this)} style={{ marginLeft: "10px" }}>Cancel</button>
                                </form>
                            </div>
                        </div>
                    </div>

                </div>
                <Modal show={this.state.isOpen} onHide={this.closeModal}>
                <Modal.Header closeButton>
                    <Modal.Title>Countries</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                <div className="list-group">
                    
                    {this.state.countries.map(country => <button onClick={this.addToSelectedCountry} type="button" className="list-group-item list-group-item-action">{country.name}</button>
                                            )}
                   
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={this.closeModal}>
                    Close
                    </Button>
                </Modal.Footer>
                </Modal>
            </div>
        );
    }
}

export default CreateElementComponent;