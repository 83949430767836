import React, { Component } from 'react';
import {Alert,Spinner} from 'react-bootstrap';
import '../assets/styles/loginpage.css';
import AuthenticationService from '../services/AuthenticationService';
import HeaderComponent from '../components/HeaderComponent';
import FooterComponent from '../components/FooterComponent';
class LoginComponent extends Component {

    constructor(props) {
        super(props)

        this.state = {
            userName: '',
            password: '',
            loading: false,
            error: false,
            isError: false
        }
        this.changeUsernameHandler = this.changeUsernameHandler.bind(this);
        this.changePasswordHandler = this.changePasswordHandler.bind(this);
        
    }

    componentDidMount() {
        console.log("componentDidMount");
        console.log(this.props.location.state);
        if('undefined' != typeof this.props.location.state){
            this.setState({ isError: this.props.location.state.isError });
        }
        
        
    }

    handleSubmit = (evt)=>{
        evt.preventDefault();
        let authRequest = { userName: this.state.userName, password: this.state.password };
        AuthenticationService.userLogin(authRequest).then((response) => {

            console.log("response", response);
            if (response.status === 200) {
                //this.props.setUser(response.data);
                localStorage.setItem('USER_KEY',response.data.token);
                this.setState({ isError: false });
                this.props.history.push('/categories');
            }
            else {
                console.log("Something Wrong!Please Try Again");
                this.setState({ isError: true });
            }
        }).catch((err) => {
            console.log("response err", err);
            this.setState({ isError: true });
        });

    }

    changeUsernameHandler = (event) => {
        this.setState({ userName: event.target.value });
    }

    changePasswordHandler = (event) => {
        this.setState({ password: event.target.value });
    }

    

    render() {
        return (
            <div>
                <HeaderComponent />
                <div className="login-page">   
                {this.state.isError  && (<Alert key={1} variant={"danger"}>
                    Unauthorized User please try again.
                    </Alert> )
                }
                              
                   <section className="h-100">
                   <div className="container h-100">
                  
                       <div className="row justify-content-md-center h-100">
                           <div className="card-wrapper">
           
                               <div className="card fat">
                                   <div className="card-body">
                                       <h4 className="card-title">Login</h4>
                                       
                                       <form className="my-login-validation" onSubmit={this.handleSubmit} noValidate={false}>
                                           <div className="form-group">
                                               <label htmlFor="email">User Name</label>
                                               <input id="username" type="text" className="form-control" minLength={5} value={this.state.userName}  onChange={this.changeUsernameHandler} name="userName" required />
                                               
                                                   <div className="invalid-feedback">
                                                       UserId is invalid
                                                   </div>
 
                                           </div>
           
                                           <div className="form-group">
                                               <label>Password
                                                   <a href="forgot.html" className="float-right">
                                                       Forgot Password?
                                                   </a>
                                               </label>
                                               <input id="password" type="password" className="form-control" minLength={8} value={this.state.password}  onChange={this.changePasswordHandler} name="password" required/>
                                               <div className="invalid-feedback">
                                                   Password is required
                                               </div>
                                           </div>
           
                                           <div className="form-group">
                                               <div className="custom-control custom-checkbox">
                                                   <input type="checkbox" className="custom-control-input" id="customCheck1" />
                                                   <label className="custom-control-label" htmlFor="customCheck1">Remember me</label>
                                                </div>
                                           </div>
                                           
           
                                           <div className="form-group m-0">
                                               <button type="submit" className="btn btn-primary">
                                                   Login
                                                   {this.state.loading && (
                                                       <Spinner
                                                       as="span"
                                                       animation="border"
                                                       size="sm"
                                                       role="status"
                                                       aria-hidden="true"
                                                     />
                                                   )}
                                                   {/* <ClipLoader
                                                   //css={override}
                                                   size={20}
                                                   color={"#123abc"}
                                                   loading={loading}
                                                   /> */}
                                               </button>
                                           </div>
                                       </form>
                                       { this.state.error &&
                                            <Alert style={{marginTop:'20px'}} variant="danger">
                                               {this.state.error}
                                           </Alert>
           
                                       }
                                       
                   
                                   </div>
                               </div>
                           </div>
                       </div>
                   </div>
               </section>
                   </div>

                   <FooterComponent />
            </div >
                );
            }
}

export default LoginComponent;