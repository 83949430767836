import React, { Component } from 'react';
import CategoryService from '../services/CategoryService';

class CreateCategoryComponent extends Component {
    constructor(props) {
        super(props)

        this.state = {
            id: this.props.match.params.id,
            title: '',
            description: ''

        }
        this.changeTitleHandler = this.changeTitleHandler.bind(this);
        this.changeDescriptionHandler = this.changeDescriptionHandler.bind(this);
        this.saveOrUpdateCategory = this.saveOrUpdateCategory.bind(this);
    }

    componentDidMount() {
        if (this.state.id === "-1") {
            return;
        } else {
            CategoryService.getCategoryById(this.state.id).then((res) => {
                let category = res.data;
                this.setState({
                    title: category.title,
                    description: category.description

                });
            }).catch((error) => {
                if(error.request.status.toString() === "401" && error.response.data.error.toString() === "Unauthorized"){
                    this.props.history.push('/');
                }
            });
        }

    }

    saveOrUpdateCategory = (e) => {
        e.preventDefault();
        let category = { title: this.state.title, description: this.state.description };
        console.log('category => ' + JSON.stringify(category));
        if (this.state.id === "-1") {
            CategoryService.createCategory(category).then(res => {
                this.props.history.push("/categories");
            }).catch((error) => {
                if(error.request.status.toString() === "401" && error.response.data.error.toString() === "Unauthorized"){
                    this.props.history.push('/');
                }
            });
        } else {
            CategoryService.updateCategory(category, this.state.id).then(res => {
                this.props.history.push("/categories");
            }).catch((error) => {
                if(error.request.status.toString() === "401" && error.response.data.error.toString() === "Unauthorized"){
                    this.props.history.push('/');
                }
            });

        }

    }

    changeTitleHandler = (event) => {
        this.setState({ title: event.target.value });
    }

    changeDescriptionHandler = (event) => {
        this.setState({ description: event.target.value });
    }


    cancel() {
        this.props.history.push('/categories');
    }
    getTitle() {
        if (this.state.id === "-1") {
            return <h3 className="text-center">Add Category</h3>
        } else {
            return <h3 className="text-center">Update Category</h3>
        }
    }
    render() {
        return (
            <div>
                <br></br>
                <div className="container">
                    <div className="row">
                        <div className="card col-md-6 offset-md-3 offset-md-3">
                            {
                                this.getTitle()
                            }
                            <div className="card-body">
                                <form>
                                    <div className="form-group">
                                        <label> Title: </label>
                                        <input placeholder="Title" name="title" className="form-control"
                                            value={this.state.title} onChange={this.changeTitleHandler} />
                                    </div>
                                    <div className="form-group">
                                        <label> Description: </label>
                                        <input placeholder="Description" name="description" className="form-control"
                                            value={this.state.description} onChange={this.changeDescriptionHandler} />
                                    </div>


                                    <button className="btn btn-success" onClick={this.saveOrUpdateCategory}>Save</button>
                                    <button className="btn btn-danger" onClick={this.cancel.bind(this)} style={{ marginLeft: "10px" }}>Cancel</button>
                                </form>
                            </div>
                        </div>
                    </div>

                </div>
            </div>
        );
    }
}

export default CreateCategoryComponent;