import axios from 'axios';
import globalConstants from '../utils/globalConstants'
const COUNTRY_API_BASE_URL = "/countries";

class CountryService {

    getToken=()=>{
        return localStorage.getItem('USER_KEY');
    }

    getCountries() {
        return  axios({
            method:'GET',
            url:globalConstants.BASE_URL + COUNTRY_API_BASE_URL,
            headers:{
                'Authorization':'Bearer '+this.getToken()
            }
        });
    }

    createCountry(country) {
        return  axios({
            method:'POST',
            url: globalConstants.BASE_URL + COUNTRY_API_BASE_URL,
            data:country,
            headers:{
                'Authorization':'Bearer '+this.getToken()
            }
        });
    }

    getCountryById(countryId) {
        return  axios({
            method:'GET',
            url:globalConstants.BASE_URL + COUNTRY_API_BASE_URL + "/" + countryId,
            headers:{
                'Authorization':'Bearer '+this.getToken()
            }
        });
    }

    updateCountry(country, countryId) {
        return  axios({
            method:'PUT',
            url:globalConstants.BASE_URL + COUNTRY_API_BASE_URL + "/" + countryId,
            data:country,
            headers:{
                'Authorization':'Bearer '+this.getToken()
            }
        });
    }

    deleteCountry(countryId) {
        return  axios({
            method:'DELETE',
            url:globalConstants.BASE_URL + COUNTRY_API_BASE_URL + "/" + countryId,
            headers:{
                'Authorization':'Bearer '+this.getToken()
            }
        });
    }

}

export default new CountryService()