import React, { Component } from 'react';
import TypeService from '../../services/TypeService';
import MeasureService from '../../services/MeasureService';
import SubCategoryService from '../../services/SubCategoryService';
import { Modal, Button} from "react-bootstrap";
class AddTypeComponent extends Component {
    
    constructor(props) {
        super(props)

        this.state = {
            id: this.props.match.params.id,
            name: '',
            subCategories: [],
            subCategory: null,
            selectedMeasures: [],
            measures: [],
            isOpen: false

        }
        this.changeNameHandler = this.changeNameHandler.bind(this);
        this.saveOrUpdateType = this.saveOrUpdateType.bind(this);
        this.changeSubCategoryHandler = this.changeSubCategoryHandler.bind(this);
        this.closeModal = this.closeModal.bind(this);
        this.openModal = this.openModal.bind(this);
        this.addToSelectedMeasure = this.addToSelectedMeasure.bind(this);
        this.ifMeasureIsAlreadyExist = this.ifMeasureIsAlreadyExist.bind(this);
    }

    componentDidMount() {
        SubCategoryService.getSubCategories().then((res) => {
            console.log(res.data);
            if(res.data.length > 0){
                this.setState({ subCategory: res.data[0] });
                console.log("componentDidMount:",res.data[0]);
            }else{
                this.props.history.push('/types');
            }
            this.setState({ subCategories: res.data })
        }).catch((error) => {
            if(error.request.status.toString() === "401" && error.response.data.error.toString() === "Unauthorized"){
                this.props.history.push('/');
            }
        });
        MeasureService.getMeasures().then(res => {
            
            console.log(res);
            if(res.data.length > 0){
                this.setState({ measures: res.data });
            }else{
                this.props.history.push('/types');
            }
        }).catch((error) => {
            if(error.request.status.toString() === "401" && error.response.data.error.toString() === "Unauthorized"){
                console.log("go to login");
                this.props.history.push({pathname: '/',state: { isError: true }});
            }
        });
        if (this.state.id === "-1") {
            return;
        } else {
            TypeService.getTypeById(this.state.id).then((res) => {
                console.log("getTypeById",res.data);
                let type = res.data;
                this.setState({
                    name: type.name,
                    subCategory: type.subCategory,
                    selectedMeasures: type.measures
                });
            }).catch((error) => {
                if(error.request.status.toString() === "401" && error.response.data.error.toString() === "Unauthorized"){
                    this.props.history.push('/');
                }
            });
        }

    }

    saveOrUpdateType = (e) => {
        e.preventDefault();
        let type = { name: this.state.name, subCategory: this.state.subCategory, measures: this.state.selectedMeasures };
        console.log('type => ' + JSON.stringify(type));
        if (this.state.id === "-1") {
            TypeService.createType(type).then(res => {
                this.props.history.push("/types");
            }).catch((error) => {
                if(error.request.status.toString() === "401" && error.response.data.error.toString() === "Unauthorized"){
                    this.props.history.push('/');
                }
            });
        } else {
            TypeService.updateType(type, this.state.id).then(res => {
                this.props.history.push("/types");
            }).catch((error) => {
                if(error.request.status.toString() === "401" && error.response.data.error.toString() === "Unauthorized"){
                    this.props.history.push('/');
                }
            });

        }

    }

    changeNameHandler = (event) => {
        this.setState({ name: event.target.value });
    }

    

    changeSubCategoryHandler = (event) => {
        
        for (let i = 0; i < this.state.subCategories.length; i++) {
            if(this.state.subCategories[i].id.toString() === event.target.value.toString()){
                console.log("changeSubCategoryHandler");
                this.setState({ subCategory: this.state.subCategories[i] });
            }       
        }
    }

    deleteSelectedMeasure = (event) => {
        console.log("deleteSelectedMeasure");
        console.log(event.target.innerText);
        var array = [...this.state.selectedMeasures];
        if(array.length === 1){
            this.setState({ selectedMeasures: []});
        }else{
            for (let i = 0; i < this.state.selectedMeasures.length; i++) {
                console.log(this.state.selectedMeasures[i].name.toString());
                
                if(this.state.selectedMeasures[i].name.toString().trim() === event.target.innerText.toString().trim()){
                    console.log("if");
                    array.splice(i, 1);
                    console.log(array);
                    this.setState({ selectedMeasures: array});
                }
                
            }
            console.log("endfor");
        }
        
        
    }

    addToSelectedMeasure = (event) =>{
        console.log(event.target.innerText);
        var array = [...this.state.selectedMeasures];
        if(!this.ifMeasureIsAlreadyExist(event.target.innerText)){
            for (let i = 0; i < this.state.measures.length; i++) {
                if(this.state.measures[i].name.toString().trim() === event.target.innerText.toString().trim()){
                    this.setState({ 
                        selectedMeasures: array.concat(this.state.measures[i]), 
                        isOpen: false
                    });
                }
            }
        }
        console.log(this.state.selectedMeasures);
    }

    ifMeasureIsAlreadyExist(name){
        for (let i = 0; i < this.state.selectedMeasures.length; i++) {
            if(this.state.selectedMeasures[i].name.toString().trim() === name.toString().trim()){
                return true;
            }
        }
        return false;
    }



    cancel() {
        this.props.history.push('/types');
    }

    getTitle() {
        if (this.state.id === "-1") {
            return <h3 className="text-center">Add Type</h3>
        } else {
            return <h3 className="text-center">Update Type</h3>
        }
    }

    openModal (){
        this.setState({ isOpen: true });
    }

    closeModal (){
         this.setState({ isOpen: false });
    }

    render() {
        return (
            <div>
                <br></br>
                <div className="container">
                    <div className="row">
                        <div className="card col-md-6 offset-md-3 offset-md-3">
                            {
                                this.getTitle()
                            }
                            <div className="card-body">
                                <form>
                                    <div className="form-group">
                                        <label> Name: </label>
                                        <input placeholder="Title" name="name" className="form-control"
                                            value={this.state.name} onChange={this.changeNameHandler} />
                                    </div>
                                    
                                    <div className="form-group">
                                        <select  className="form-select" aria-label="Default select example" name="category" onChange={this.changeSubCategoryHandler}>
                                            {this.state.subCategories.map(subCategory =>
                                            <option key={subCategory.id} selected={this.state.subCategory.id === subCategory.id} value={subCategory.id}>{subCategory.name}</option>
                                            )};
                                        </select>
                                    </div>

                                    <div className="form-group">
                                        <Button variant="primary" onClick={this.openModal}>
                                            Launch Measure list
                                        </Button>
                                    </div>

                                    <div className="form-group">
                                    <div className="list-group">
                                        <label> Selected Measures: </label>
                                            { this.state.selectedMeasures.map(measure => <button onClick={this.deleteSelectedMeasure} type="button" className="list-group-item list-group-item-action">{measure.name}</button> ) 
                                            }
                                        </div>
                                    </div>

                                    <button className="btn btn-success" onClick={this.saveOrUpdateType}>Save</button>
                                    <button className="btn btn-danger" onClick={this.cancel.bind(this)} style={{ marginLeft: "10px" }}>Cancel</button>
                                </form>
                            </div>
                        </div>
                    </div>

                </div>
                <Modal show={this.state.isOpen} onHide={this.closeModal}>
                <Modal.Header closeButton>
                    <Modal.Title>Measures</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                <div className="list-group">
                    {this.state.measures.map(measure => <button onClick={this.addToSelectedMeasure} type="button" className="list-group-item list-group-item-action">{measure.name}</button>
                                            )}
                   
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={this.closeModal}>
                    Close
                    </Button>
                </Modal.Footer>
                </Modal>
            </div>
        );
    }
}

export default AddTypeComponent;