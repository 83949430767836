import React, { Component } from 'react';
import MeasureService from '../../services/MeasureService';

class AddMeasureComponent extends Component {
    constructor(props) {
        super(props)

        this.state = {
            id: this.props.match.params.id,
            name: '',

        }
        this.changeNameHandler = this.changeNameHandler.bind(this);
        
        this.saveOrUpdateMeasure = this.saveOrUpdateMeasure.bind(this);
    }

    componentDidMount() {
        if (this.state.id === "-1") {
            return;
        } else {
            MeasureService.getMeasureById(this.state.id).then((res) => {
                let measure = res.data;
                this.setState({
                    name: measure.name,

                });
            }).catch((error) => {
                if(error.request.status.toString() === "401" && error.response.data.error.toString() === "Unauthorized"){
                    this.props.history.push('/');
                }
            });
        }
    }

    saveOrUpdateMeasure = (e) => {
        e.preventDefault();
        let measure = { name: this.state.name,  };
        console.log('measure => ' + JSON.stringify(measure));
        if (this.state.id === "-1") {
            MeasureService.createMeasure(measure).then(res => {
                this.props.history.push("/measures");
            }).catch((error) => {
                if(error.request.status.toString() === "401" && error.response.data.error.toString() === "Unauthorized"){
                    this.props.history.push('/');
                }
            });
        } else {
            MeasureService.updateMeasure(measure, this.state.id).then(res => {
                this.props.history.push("/measures");
            }).catch((error) => {
                if(error.request.status.toString() === "401" && error.response.data.error.toString() === "Unauthorized"){
                    this.props.history.push('/');
                }
            });

        }

    }

    changeNameHandler = (event) => {
        this.setState({ name: event.target.value });
    }

    

    cancel() {
        this.props.history.push('/measures');
    }
    getTitle() {
        if (this.state.id === "-1") {
            return <h3 className="text-center">Add Measure</h3>
        } else {
            return <h3 className="text-center">Update Measure</h3>
        }
    }
    render() {
        return (
            <div>
                <br></br>
                <div className="container">
                    <div className="row">
                        <div className="card col-md-6 offset-md-3 offset-md-3">
                            {
                                this.getTitle()
                            }
                            <div className="card-body">
                                <form>
                                    <div className="form-group">
                                        <label> Name: </label>
                                        <input placeholder="Title" name="name" className="form-control"
                                            value={this.state.name} onChange={this.changeNameHandler} />
                                    </div>
                                   

                                    <button className="btn btn-success" onClick={this.saveOrUpdateMeasure}>Save</button>
                                    <button className="btn btn-danger" onClick={this.cancel.bind(this)} style={{ marginLeft: "10px" }}>Cancel</button>
                                </form>
                            </div>
                        </div>
                    </div>

                </div>
            </div>
        );
    }
}

export default AddMeasureComponent;