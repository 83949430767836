import React, { Component } from 'react';
import CategoryService from '../services/CategoryService';
import Navbar from '../components/navbar/NavBar';
import '../assets/styles/globalstyle.css';
class ListCategoryComponent extends Component {
    
    constructor(props) {
        super(props)

        this.state = {
            categories: []
        }
        this.addCategory = this.addCategory.bind(this);
        this.editCategory = this.editCategory.bind(this);
        this.deleteCategory = this.deleteCategory.bind(this);
        this.subCategoryList = this.subCategoryList.bind(this);
    }

    componentDidMount() {
        CategoryService.getCategories().then((res) => {
            console.log(res.data);
            this.setState({ categories: res.data })
        }).catch((error) => {
            if(error.request.status.toString() === "401" && error.response.data.error.toString() === "Unauthorized"){
                this.props.history.push('/');
            }
        });
    }

    addCategory(id) {
        this.props.history.push('/add-category/-1');
    }

    subCategoryList(id) {
        this.props.history.push('/subcategories');
    }

    editCategory(id) {
        this.props.history.push(`/add-category/${id}`);
    }

    deleteCategory(id) {
        CategoryService.deleteCategory(id).then((res) => {
            this.setState({
                categories: this.state.categories.filter(category => category.id !== id)
            });
        }).catch((error) => {
            if(error.request.status.toString() === "401" && error.response.data.error.toString() === "Unauthorized"){
                this.props.history.push('/');
            }
        });
    }

    render() {
        return (
            <div>
                <Navbar />
                <div className="container width-60" >
                <h2 className="text-center">Categories List</h2>
                    <div className="row">
                        <div className="col-md-9"></div>
                        <div className="col-md-3"><button className="btn btn-primary" onClick={this.addCategory}>Add Category</button></div>
                        
                    </div>
                <br></br>
                <div className="row">
                    <table className="table table-striped table-bordred">
                        <thead>
                            <tr>
                                <th>Category Title </th>
                                <th>Category Description </th>
                                <th>Actions </th>
                            </tr>
                        </thead>
                        <tbody>
                            {
                                this.state.categories.map(
                                    category =>
                                        <tr key={category.id}>
                                            <td> {category.title} </td>
                                            <td> {category.description} </td>

                                            <td>

                                                <button onClick={() => this.editCategory(category.id)} className="btn btn-info mr-2">Update </button>
                                                <button onClick={() => this.deleteCategory(category.id)} className="btn btn-danger">Delete </button>
                                            </td>
                                        </tr>
                                )
                            }
                        </tbody>
                    </table>
                </div>
                </div>
                
            </div >
        );
    }
}

export default ListCategoryComponent;