
import './App.css';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import ListCategoryComponent from './components/ListCategoryComponent';


import CreateCategoryComponent from './components/CreateCategoryComponent';
import CreateElementComponent from './components/CreateElementComponent';
import ListElementComponent from './components/ListElementComponent';
import LoginComponent from './components/LoginComponent';
import SubCategoryListComponent from './components/SubCategory/SubCategoryListComponent';
import AddSubCategoryComponent from './components/SubCategory/AddSubCategoryComponent';
import TypeListComponent from './components/Type/TypeListComponent';
import AddTypeComponent from './components/Type/AddTypeComponent';
import AddCountryComponent from './components/country/AddCountryComponent';
import CountryListComponent from './components/country/CountryListComponent';
import MeasureListComponent from './components/measure/MeasureListComponent';
import AddMeasureComponent from './components/measure/AddMeasureComponent'
function App() {
  return (
    <div>
      <Router>
        
        
        <div className="">
          <Switch>
            <Route path="/" exact component={LoginComponent} ></Route>
            <Route path="/categories" component={ListCategoryComponent} ></Route>
            <Route path="/add-category/:id" component={CreateCategoryComponent} ></Route>
            <Route path="/subcategories" component={SubCategoryListComponent} ></Route>
            <Route path="/add-subcategory/:id" component={AddSubCategoryComponent} ></Route>
            <Route path="/types" component={TypeListComponent} ></Route>
            <Route path="/add-type/:id" component={AddTypeComponent} ></Route>
            <Route path="/elements" component={ListElementComponent} ></Route>
            <Route path="/add-element/:id" component={CreateElementComponent} ></Route>
            <Route path="/add-country/:id" component={AddCountryComponent} ></Route>
            <Route path="/countries" component={CountryListComponent} ></Route>
            <Route path="/measures" component={MeasureListComponent} ></Route>
            <Route path="/add-measure/:id" component={AddMeasureComponent} ></Route>
          </Switch>
        </div>
        
      </Router>

    </div>
  );
}

export default App;
