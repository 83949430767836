import axios from 'axios';
import globalConstants from '../utils/globalConstants'
const MEASURE_API_BASE_URL = "/measures";

class MeasureService {

    getToken=()=>{
        return localStorage.getItem('USER_KEY');
    }

    getMeasures() {
        return  axios({
            method:'GET',
            url:globalConstants.BASE_URL + MEASURE_API_BASE_URL,
            headers:{
                'Authorization':'Bearer '+this.getToken()
            }
        });
    }

    createMeasure(measure) {
        return  axios({
            method:'POST',
            url: globalConstants.BASE_URL + MEASURE_API_BASE_URL,
            data:measure,
            headers:{
                'Authorization':'Bearer '+this.getToken()
            }
        });
    }

    getMeasureById(measureId) {
        return  axios({
            method:'GET',
            url:globalConstants.BASE_URL + MEASURE_API_BASE_URL + "/" + measureId,
            headers:{
                'Authorization':'Bearer '+this.getToken()
            }
        });
    }

    updateMeasure(measure, measureId) {
        return  axios({
            method:'PUT',
            url:globalConstants.BASE_URL + MEASURE_API_BASE_URL + "/" + measureId,
            data:measure,
            headers:{
                'Authorization':'Bearer '+this.getToken()
            }
        });
    }

    deleteMeasure(measureId) {
        return  axios({
            method:'DELETE',
            url:globalConstants.BASE_URL + MEASURE_API_BASE_URL + "/" + measureId,
            headers:{
                'Authorization':'Bearer '+this.getToken()
            }
        });
    }

}

export default new MeasureService()